<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      size="lg"
      :title="title"
      @ok="accept($event)"
      @hidden="resetData"
    >
      <b-form>
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="description"
            >
              <label for="description">Các yếu tố nguy hiểm, yếu tố có hại đã được nhận diện</label>
              <b-form-input
                v-model="dataInput.element"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="description"
            >
              <label for="description">Mức độ nghiêm trọng</label>
              <b-form-input
                v-model="dataInput.level"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="description"
            >
              <label for="description">Biện pháp phòng,chống các yếu tố nguy hiểm, yếu tố có hại</label>
              <b-form-input
                v-model="dataInput.solution"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="description"
            >
              <label for="description">Người/bộ phận thực hiện các biện pháp phòng, chống các yếu tố nguy hiểm, yếu tố có hại</label>
              <b-form-input
                v-model="dataInput.responsility"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
            xl="6"
          >
            <b-form-group
              label-for="description"
            >
              <label for="description">Thời gian thực hiện các biện pháp phòng, chống các yếu tố nguy hiểm, yếu tố có hại</label>
              <b-form-input
                v-model="dataInput.time"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      dataInput: {
        element: '',
        level: '',
        solution: '',
        responsility: '',
        time: '',
      },

    }
  },
  methods: {
    accept(bvModalEvt) {
      this.$emit('accept', this.dataInput)
    },
    resetData() {
      this.dataInput = {
        element: '',
        level: '',
        solution: '',
        responsility: '',
        time: '',
      }
    },

  },
}
</script>
<style lang="scss">
  .btn-down {
    display: grid;
    margin-bottom: 15px;
    align-items: end;
  }
</style>
