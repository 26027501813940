<template>
  <div
    id="labor-recruitment-id"
  >
    <!--phần header-->
    <button-all-header
      :contentBtnAdd="'Thêm mới'"
      :arrayExcel="[]"
      :hideImportFile="false"
      :hideExportFile="false"
      :hideDowload="false"
      :hideSearch="false"
      :showBtnMultiDelete="showBtnMultiDelete"
      @importFile="() => {}"
      @clickDowloadSample="() => {}"
      @clickExportExcel="() => {}"
      @clickDelete="deleteItems"
      @clickAdd="showModalCreate"
    />
    <vue-good-table
      ref="user-table"
      :columns="columns"
      :rows="dataList || []"
      :select-options="{
        enabled: true,
        vertialAlignTop: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      @on-selected-rows-change="selectRowTable"
    >
      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Chức năng -->
        <span v-if="props.column.field == 'action'">
          <feather-icon
            icon="Trash2Icon"
            size="18"
            class="text-body ml-2"
            @click="deleteItem(props.row.id)"
          />

        </span>
      </template>
    </vue-good-table>

    <my-pagination
      :totalItems="totalRecord"
      @pageClick="handlePageClick"
    />

    <!-- thêm mới modal  -->
    <modal-create
      :id="modalIdCreate"
      title="Thêm mới"
      @accept="handleModal"
    />

    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import ModalCreate from './ModalCreate.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../../constants/ConstantsApi'

export default {
  components: {
    VueGoodTable,
    ButtonAllHeader,
    ModalCreate,
    ConfirmModal,
    MyPagination,
  },
  props: {
    submitReportId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalRecord: 0,
      showBtnMultiDelete: false,
      dataList: [],
      columns: [
        {
          label: 'CÁC YẾU TỐ NGUY HIỂM, YẾU TỐ CÓ HẠI ĐÃ ĐƯỢC NHẬN DIỆN',
          field: 'element',
          sortable: false,
        },
        {
          label: 'MỨC ĐỘ NGHIÊM TRỌNG',
          field: 'level',
          sortable: false,
        },
        {
          label: 'BIỆN PHÁP PHÒNG, CHỐNG CÁC YẾU TỐ NGUY HIỂM, YẾU TỐ CÓ HẠI',
          field: 'solution',
          sortable: false,
        },
        {
          label: 'NGƯỜI/ BỘ PHẬN THỰC HIỆN CÁC BIỆN PHÁP PHÒNG, CHỐNG CÁC YẾU TỐ NGUY HIỂM, YẾU TỐ CÓ HẠI',
          field: 'responsility',
          sortable: false,
        },
        {
          label: 'THỜI GIAN THỰC HIỆN CÁC BIỆN PHÁP PHÒNG, CHỐNG CÁC YẾU TỐ NGUY HIỂM, YẾU TỐ CÓ HẠI',
          field: 'time',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
        },
      ],
      urlQuery: {
        submitReportId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalIdCreate: 'modalIdCreate',
    }
  },
  created() {
    this.fetchData(this.urlQuery)
  },
  methods: {

    handlePageClick(pageNumber, pageSize) {
      this.queryParams = {
        ...this.queryParams,
        pageNumber,
        pageSize,
      }
      this.fetchData(this.urlQuery)
    },
    async handleModal(param) {
      const payload = {
        ...param,
        submitReportId: this.submitReportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.CREATE_SUBMIT_REPORT_FIRST_EVALUATION, payload).then(response => {
        if (response.status === 200) {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm mới thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.$bvModal.hide(this.modalIdCreate)
          this.fetchData(this.urlQuery)
        }
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    // Mở modal tạo và chỉnh sửa kiểm định viên

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    async deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Thực hiện delete
    async deleteAction() {
      const payload = {
        ids: this.deleteIds,
        submitReportId: this.submitReportId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_SUBMIT_REPORT_FIRST_EVALUATION, payload).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchData(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Fetch data
    async fetchData(urlQuery) {
      this.$showLoading()
      this.urlQuery.submitReportId = this.submitReportId
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_SUBMIT_REPORT_FIRST_EVALUATION, {
        params: urlQuery,
      })
      this.dataList = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },
    // Hiển thị modal thêm
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalIdCreate)
    },
    search(val) {
      if (val != null) {
        this.urlQuery.declareEquipmentId = this.$route.params.id
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchData(this.urlQuery)
      } else {
        this.urlQuery.declareEquipmentId = this.$route.params.id
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchData(this.urlQuery)
      }
    },
  },
}
</script>

<style lang="scss">
// .scroll-table {
//   overflow-y: scroll;
//   height: 500px;
// }
</style>
