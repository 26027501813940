var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"labor-recruitment-id"}},[_c('button-all-header',{attrs:{"contentBtnAdd":'Thêm mới',"arrayExcel":[],"hideImportFile":false,"hideExportFile":false,"hideDowload":false,"hideSearch":false,"showBtnMultiDelete":_vm.showBtnMultiDelete},on:{"importFile":function () {},"clickDowloadSample":function () {},"clickExportExcel":function () {},"clickDelete":_vm.deleteItems,"clickAdd":_vm.showModalCreate}}),_c('vue-good-table',{ref:"user-table",attrs:{"columns":_vm.columns,"rows":_vm.dataList || [],"select-options":{
      enabled: true,
      vertialAlignTop: true,
      selectOnCheckboxOnly: true,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }},on:{"on-selected-rows-change":_vm.selectRowTable},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'action')?_c('span',[_c('feather-icon',{staticClass:"text-body ml-2",attrs:{"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.deleteItem(props.row.id)}}})],1):_vm._e()]}}])}),_c('my-pagination',{attrs:{"totalItems":_vm.totalRecord},on:{"pageClick":_vm.handlePageClick}}),_c('modal-create',{attrs:{"id":_vm.modalIdCreate,"title":"Thêm mới"},on:{"accept":_vm.handleModal}}),_c('confirm-modal',{attrs:{"id":_vm.confirmModalId,"content":_vm.modalContent},on:{"accept":_vm.deleteAction}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }